import { L } from '@lib/abpUtility'
import { CalendarOutlined, UserOutlined } from '@ant-design/icons/lib'
import { renderDate } from '@lib/helper'

const columns = (actionColumn?) => {
  const data = [
    {
      title: L('ID'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      ellipsis: true,
      render: (id) => <>{id}</>
    },
    {
      title: L('CREATE_USER'),
      dataIndex: 'userId',
      key: 'userId',
      width: 160,
      ellipsis: true,
      render: (userId, row) => <>{row.user.displayName}</>
    },
    {
      title: L('UNIT'),
      dataIndex: 'unitId',
      key: 'unitId',
      width: 140,
      ellipsis: true,
      render: (unitId, row) => <>{row.unit?.name}</>
    },
    {
      title: L('BEDROOM'),
      dataIndex: 'numOfBedroom',
      key: 'numOfBedroom',
      width: 100,
      ellipsis: true,
      render: (numOfBedroom) => <>{numOfBedroom ?? 0}</>
    },
    {
      title: L('BATHROOM'),
      dataIndex: 'numOfBathroom',
      key: 'numOfBathroom',
      width: 100,
      ellipsis: true,
      render: (numOfBathroom) => <>{numOfBathroom ?? 0}</>
    },
    {
      title: L('STATUS'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      ellipsis: true,
      render: (status) => <>{L(status?.name)}</>
    },
    {
      title: L('ASSIGN_USER'),
      dataIndex: 'assignUserId',
      key: 'assignUserId',
      width: 100,
      ellipsis: true,
      render: (assignUserId, row) => <>{row.assignUser?.displayName}</>
    },
    {
      title: L('CREATED_AT'),
      dataIndex: 'creationTime',
      key: 'creationTime',
      width: 150,
      ellipsis: true,
      render: (text, row) => (
        <div className="text-muted small">
          <CalendarOutlined className="mr-1" /> {renderDate(text)}
          <div>
            <UserOutlined className="mr-1" /> {row.creatorUser?.displayName}
          </div>
        </div>
      )
    }
  ]

  if (actionColumn) {
    data.push(actionColumn)
  }

  return data
}

export default columns
