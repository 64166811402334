import type { PagedResultDto } from '../dto/pagedResultDto'
import http from '../httpService'
import { L, LNotification } from '../../lib/abpUtility'
import { notifyError, notifySuccess } from '../../lib/helper'
import {
  RowCompanyModel,
  CompanyModel,
  CompanyTypeModel
} from '../../models/Project/Company/CompanyModel'
import { downloadFile } from '@lib/helperFile'

class companyService {
  public async create(body: any) {
    body.reasonForVisitId = 1
    const res = await http.post(
      'api/services/app/Contracts/CreateCompany',
      body
    )
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('SAVING_SUCCESSFULLY')
    )
    return CompanyModel.assign(res.data.result)
  }

  public async update(body: any) {
    const res = await http.post('api/services/app/Contracts/EditCompany', body)
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('SAVING_SUCCESSFULLY')
    )
    return CompanyModel.assign(res.data.result)
  }

  public async delete(id: number) {
    const res = await http.delete('api/services/app/Contracts/Delete', {
      params: { id }
    })
    return res.data
  }

  public async activateOrDeactivate(id: number, isActive) {
    const res = await http.post(
      'api/services/app/Contracts/ActiveCompany',
      {},
      { params: { id, isActive } }
    )
    return res.data
  }

  public async get(id: number): Promise<any> {
    if (!id) {
      notifyError(L('ERROR'), L('ENTITY_NOT_FOUND'))
    }

    const res = await http.get('api/services/app/Contracts/GetCompany', {
      params: { id }
    })
    const result = CompanyModel.assign(res.data.result)
    return result
  }

  public async getCompanyTypes(): Promise<any> {
    const res = await http.get('api/services/app/Contracts/GetCompanyTypes')
    const result = CompanyTypeModel.assigns(res.data.result)
    return result
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    if (params.keyword) {
      params.keyword = encodeURIComponent(params.keyword)
    }

    const res = await http.get('api/services/app/Contracts/FilterCompanies', {
      params
    })
    const { result } = res.data
    result.items = RowCompanyModel.assigns(result.items)
    return result
  }

  public async getAllMyCompany(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get('api/services/app/Contracts/GetAllMyCompany', {
      params
    })
    return res.data.result
  }

  public async exportCompany(params: any): Promise<any> {
    const res = await http.get('api/Export/ExportCompany', {
      params,
      responseType: 'blob'
    })
    downloadFile(res.data, 'workorder.xlsx')
  }
}

export default new companyService()
