import React from 'react'
import { Form, Select } from 'antd'
import { L } from '@lib/abpUtility'
import AppConsts from '@lib/appconst'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { renderOptions } from '@lib/helper'
import { OptionModel } from '@models/global'

const { formVerticalLayout } = AppConsts
interface FormSelectProps {
  label?: string
  name: string | (string | number)[]
  rule?
  options: OptionModel[]
  selectProps?: SelectProps<SelectValue>
  disabled?: boolean
  onChange?: (value: any, options: any | Array<any>) => void
  optionModal?: (item: any, index: any) => void
  formItemClass?: string
  placeholder?: string
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  name,
  rule,
  options,
  selectProps,
  disabled = false,
  onChange,
  optionModal,
  formItemClass,
  placeholder
}) => {
  return (
    <Form.Item
      label={label ? L(label) : undefined}
      name={name}
      rules={rule}
      className={formItemClass}
      {...formVerticalLayout}>
      <Select
        showSearch
        showArrow
        allowClear
        filterOption={false}
        className="full-width"
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        size="large"
        {...selectProps}>
        {optionModal
          ? (options || []).map((item, index) => optionModal(item, index))
          : renderOptions(options)}
      </Select>
    </Form.Item>
  )
}

export default FormSelect
