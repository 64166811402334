import type { PagedResultDto } from '@services/dto/pagedResultDto'
import http from '../httpService'
import { FeeDetailModel, IFeeRefundModel, IFeeUpdate } from '@models/fee'
import { downloadFile } from '@lib/helperFile'

class FeeService {
  public async update(body: IFeeUpdate) {
    const result = await http.put('/api/services/app/FeeStatement/Update', body)
    return result.data.result
  }

  public activate(id: number, isActive) {
    return http.post(
      '/api/services/app/FeeStatement/Active',
      { id },
      {
        params: { isActive }
      }
    )
  }

  public deActivate(body) {
    return http.post('/api/services/app/FeeStatement/MarkInActive', body, {
      params: { isActive: false }
    })
  }

  public async refundReservationDeposit(body: IFeeRefundModel) {
    const result = await http.post(
      '/api/services/app/FeeReceipt/RefundFeeStatement',
      body
    )
    return result.data.result
  }

  public async refundReceipt(body: IFeeRefundModel) {
    const result = await http.post(
      '/api/services/app/FeeReceipt/RefundReceipt',
      body
    )
    return result.data.result
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get('/api/services/app/FeeStatement/GetFeeDetails', {
      params
    })
    const result = res.data.result
    if (result.items) {
      result.items = FeeDetailModel.assigns(result.items)
    }
    return result
  }

  public async exportFees(params: any): Promise<any> {
    const res = await http.get('/api/FeeStatements/ExportFeeStatementDetail', {
      params,
      responseType: 'blob'
    })
    downloadFile(res.data, 'fees.xlsx')
  }

  public async downloadTemplate() {
    const response = await http.get(
      '/api/Imports/FeeStatement/GetTemplateImport',
      { responseType: 'blob' }
    )
    downloadFile(response.data, 'fee_template.xlsx')
  }

  public async importFee(file, packageId) {
    const data = new FormData()
    data.append('file', file)
    return await http.post('/api/Imports/FeeStatement/ImportFromExcel', data, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      params: { packageId }
    })
  }

  public showHideToResident(data) {
    return http.put(
      '/api/services/app/FeeStatement/UpdateShowToResident',
      { id: data.id },
      { params: { isShowToResident: data.isShowToResident } }
    )
  }

  public async summary(params) {
    const res = await http.get(
      '/api/services/app/FeeStatement/GetFeeDetailSummary',
      { params }
    )
    return res.data?.result || []
  }

  public async getReport(params) {
    const res = await http.get(
      '/api/services/app/FeeStatement/GetDashboardStatus',
      { params }
    )
    return res.data.result
  }

  public async getPaymentChannels(params) {
    const res = await http.get(
      'api/services/app/FeeStatement/GetListPaymentChannels',
      { params }
    )
    return res.data.result
  }
  public async getListPaymentChannels(params) {
    const res = await http.get(
      'api/services/app/FeeReceipt/GetListPaymentChannels',
      { params }
    )
    return res.data.result
  }

  public async getAuditLog(params) {
    const res = await http.get('/api/services/app/FeeStatement/GetAuditLog', {
      params
    })
    return res.data.result
  }

  public async markActiveFees(body) {
    const res = await http.post(
      'api/services/app/FeeStatement/MarkActive',
      body
    )
    return res.data.result
  }
  public async markInactiveFees(body) {
    const res = await http.post(
      'api/services/app/FeeStatement/MarkInActive',
      body
    )
    return res.data.result
  }

  public async markShowToResident(body) {
    const res = await http.post(
      'api/services/app/feeStatement/MarkShowHideToResident',
      body
    )
    return res.data.result
  }
  public async markNotShowToResident(body) {
    const res = await http.post(
      'api/services/app/feeStatement/MarkShowHideToResident',
      body
    )
    return res.data.result
  }

  public async reportPackageSummary(params) {
    const res = await http.get(
      '/api/services/app/FeeStatement/GetGroupPackageSummary',
      { params }
    )
    return res.data.result
  }
  public async createFee(body) {
    const res = await http.post('api/services/app/FeeStatement/Create', body)
    return res.data.result
  }

  public async getDetailVoucher(id): Promise<any> {
    const res = await http.get('api/services/app/ExpenseMandate/GetDetail', {
      params: { id }
    })

    return res.data.result
  }
}

export default new FeeService()
