import { action, observable, makeObservable } from 'mobx'

import type { PagedResultDto } from '@services/dto/pagedResultDto'
import receiptService from '@services/fee/receiptService'

class ReceiptStore {
  @observable isLoading!: boolean
  @observable pagedResult!: PagedResultDto<any>
  @observable receiptDetail: any = undefined
  @observable receiptOverview: any[] = []
  constructor() {
    makeObservable(this)
    this.pagedResult = {
      items: [],
      totalCount: 0
    }
  }

  async create(body: any) {
    this.isLoading = true
    return receiptService.create(body).finally(() => (this.isLoading = false))
  }

  @action async getOverview(params) {
    this.isLoading = true
    this.receiptOverview = await receiptService
      .getOverview(params)
      .finally(() => (this.isLoading = false))
  }

  @action
  async getAll(params: any) {
    this.isLoading = true
    this.pagedResult = await receiptService
      .getAll(params)
      .finally(() => (this.isLoading = false))
  }
  @action
  async getDetail(id) {
    this.isLoading = true
    this.receiptDetail = await receiptService
      .getDetail(id)
      .finally(() => (this.isLoading = false))
  }

  @action
  async delete(params) {
    await receiptService.delete(params)
    this.pagedResult.items = this.pagedResult.items.filter(
      (item) => item.id !== params.id
    )
  }

  @action
  async downloadReceipt(params) {
    await receiptService.downloadReceipts(params)
  }
}

export default ReceiptStore
