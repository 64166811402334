import type { PagedResultDto } from '@services/dto/pagedResultDto'
import http from '../httpService'
// import moment from 'moment-timezone/moment-timezone'
import { downloadFile } from '@lib/helperFile'
import { notifyError, notifySuccess } from '@lib/helper'
import { L, LNotification } from '@lib/abpUtility'

class ReceiptService {
  public async create(body: any) {
    if (!body.feeDetails || !body.feeDetails.length) {
      notifyError(L('ERROR'), L('PLEASE_SELECT_FEE_ITEM_TO_MAKE_RECEIPT'))
      throw Error(L('PLEASE_SELECT_FEE_ITEM_TO_MAKE_RECEIPT'))
    }
    const result = await http.post(
      '/api/services/app/FeeReceipt/CreateReceipt',
      body
    )
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('ITEM_CREATE_SUCCEED')
    )
    return result.data.result
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const result = await http.get('/api/services/app/FeeReceipt/GetAll', {
      params
    })
    return result.data.result
  }

  public async getDetail(id): Promise<any> {
    const result = await http.get(
      'api/services/app/FeeReceipt/GetReceiptDetail',
      { params: { id } }
    )
    return result.data.result
  }
  public async delete(params) {
    return http.delete('/api/services/app/FeeReceipt/RemoveReceipt', { params })
  }

  public async getOverview(params) {
    const res = await http.get(
      'api/services/app/FeeReceipt/GetReceiptOverview',
      { params }
    )
    return res.data.result
  }

  public async getOutStanding(params): Promise<any> {
    const result = await http.get(
      'api/services/app/FeeReceipt/GetOutStanding',
      { params }
    )
    return result.data.result
  }

  public async downloadReceipts(params) {
    // const [fromDate, toDate] = params.dateFromTo || []
    // params.fromDate = fromDate ? moment(fromDate).startOf('day').toJSON() : null
    // params.toDate = toDate ? moment(toDate).endOf('day').toJSON() : null
    // delete params.dateFromTo

    const response = await http.get('api/FeeReceipts/ExportFeeReceipt', {
      responseType: 'blob',
      params
    })
    downloadFile(response.data, 'fee-receipts.xlsx')
  }
}

export default new ReceiptService()
