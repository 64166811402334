import LoadableComponent from '../../Loadable'
import {
  AuditOutlined,
  ExceptionOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FormatPainterOutlined,
  InteractionOutlined,
  LogoutOutlined,
  ProfileOutlined,
  RadarChartOutlined,
  MoreOutlined,
  TagOutlined,
  UserOutlined,
  CommentOutlined,
  ScheduleOutlined,
  FundViewOutlined,
  ControlOutlined,
  CodepenOutlined,
  CustomerServiceOutlined,
  ClusterOutlined,
  ShopOutlined,
  FileTextOutlined,
  ReadOutlined,
  SoundOutlined,
  SelectOutlined,
  ContactsOutlined,
  CarryOutOutlined,
  BarcodeOutlined,
  SplitCellsOutlined,
  FileImageOutlined,
  SolutionOutlined,
  TranslationOutlined,
  BoxPlotOutlined,
  LayoutOutlined
} from '@ant-design/icons'
import {
  DollarOutlined,
  FileProtectOutlined,
  PieChartOutlined
} from '@ant-design/icons/lib'
import { appPermissions } from '@lib/appconst'

export const layouts: any = {
  userLayout: 'userLayout',
  portalLayout: 'appLayout',
  publicLayout: 'publicLayout'
}

export const layoutRouter: any = {
  userLayout: LoadableComponent(() => import('../UserLayout')),
  appLayout: LoadableComponent(() => import('../AppLayout')),
  publicLayout: LoadableComponent(() => import('../PublicLayout'))
}

export const publicLayout: any = {
  publicNews: {
    path: '/public/news/:id',
    title: 'NEWS',
    layout: layouts.publicLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/news/detail/public-news')
    )
  },
  termAndCondition: {
    path: '/public/terms-and-conditions',
    title: 'TERM_CONDITIONS',
    layout: layouts.publicLayout,
    component: LoadableComponent(
      () => import('../../../scenes/term-condition/term-condition')
    )
  },
  publicHandoverBooking: {
    path: '/public/handover-booking',
    title: 'HANDOVER_BOOKING',
    layout: layouts.publicLayout,
    component: LoadableComponent(
      () => import('../../../scenes/handover/handoverBooking')
    )
  },
  publicHandoverBookingConfirmed: {
    path: '/public/handover-booking-confirmed/:id',
    title: 'HANDOVER_BOOKING_CONFIRMED',
    layout: layouts.publicLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/handover/handoverBooking/HandoverBookingConfirmed'
        )
    )
  },
  printReceipt: {
    path: '/public/print-receipt/:id',
    title: 'Receipt',
    layout: layouts.publicLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/feeStatement/receipt/components/ReceiptToPrint')
    )
  },
  printReport: {
    path: '/public/print-report',
    title: 'Print Report',
    layout: layouts.publicLayout,
    component: LoadableComponent(
      () => import('../../../scenes/common/Report/ReportToPrint')
    )
  },
  publicVNPay: {
    path: '/public/VNPayResult',
    title: 'VNPay Result',
    layout: layouts.publicLayout,
    component: LoadableComponent(
      () => import('../../../scenes/vnpay/VnPayResultPage')
    )
  }
}

export const userLayout: any = {
  accountLogin: {
    path: '/login',
    title: 'LogIn',
    layout: layouts.userLayout,
    component: LoadableComponent(() => import('../../../scenes/accounts/Login'))
  },
  forgotPassword: {
    path: '/forgot-password',
    title: 'FORGOT_PASSWORD',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/ForgotPassword')
    )
  },
  resetPassword: {
    path: '/reset-password',
    title: 'RESET_PASSWORD',
    layout: layouts.userLayout,
    component: LoadableComponent(
      () => import('../../../scenes/accounts/ForgotPassword')
    )
  }
}

export const portalLayouts: any = {
  welcomePage: {
    path: '/',
    title: 'Welcome Page',
    name: 'WELCOME_PAGE',
    layout: layouts.portalLayout,
    icon: LogoutOutlined,
    component: LoadableComponent(() => import('../../../scenes/Welcome'))
  },
  // Portal
  appSetting: {
    path: '/app-setting',
    permission: appPermissions.appSetting.page,
    title: 'App Setting',
    name: 'APP_SETTING',
    layout: layouts.portalLayout,
    icon: LogoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/appSetting/AppSetting')
    )
  },
  accountLogout: {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'LOGOUT',
    layout: layouts.portalLayout,
    icon: LogoutOutlined,
    component: LoadableComponent(() => import('../../Logout'))
  },
  exception: {
    path: '/exception',
    permission: '',
    title: 'exception',
    name: 'EXCEPTION',
    layout: layouts.portalLayout,
    icon: ExceptionOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Exception')
    )
  },
  dashboard: {
    path: '/dashboard',
    name: 'DASHBOARD',
    permission: appPermissions.dashboard.page,
    title: 'Dashboard',
    layout: layouts.portalLayout,
    icon: PieChartOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Dashboard')
    )
  },
  report: {
    path: '/report',
    name: 'REPORT',
    permission: appPermissions.dashboard.report,
    title: 'Report',
    layout: layouts.portalLayout,
    icon: FundViewOutlined,
    component: LoadableComponent(() => import('../../../scenes/common/Report'))
  },
  dashboardBI: {
    path: '/dashboard-overview',
    name: 'DASHBOARD_OVERVIEW',
    permission: appPermissions.dashboard.overview,
    title: 'Dashboard',
    layout: layouts.portalLayout,
    icon: PieChartOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/DashboardBI/DashBoardWithChartJS')
    )
  },
  adminMasterData: {
    path: '/master-data',
    name: 'MASTER_DATA',
    permission: appPermissions.adminMasterData.page,
    title: 'Master Data',
    layout: layouts.portalLayout,
    icon: BoxPlotOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/MasterData')
    )
  },
  adminMasterDataCreate: {
    path: '/master-data-create',
    permission: appPermissions.adminMasterData.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/MasterData/MasterDataDetail')
    )
  },
  adminMasterDataDetail: {
    path: '/master-data-detail/:id',
    permission: appPermissions.adminMasterData.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/MasterData/MasterDataDetail')
    )
  },
  notification: {
    path: '/user-notification',
    name: 'USER_NOTIFICATION',
    permission: '',
    title: 'User Notification',
    layout: layouts.portalLayout,
    icon: RadarChartOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/common/Notification')
    )
  },
  // Staff
  staffManagement: {
    path: '/staffs',
    name: 'STAFF_MANAGEMENT',
    permission: appPermissions.staff.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/member/staff'))
  },
  staffCreate: {
    path: '/staff-create',
    name: 'STAFF_MANAGEMENT_CREATE',
    permission: appPermissions.staff.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/staff/StaffDetail')
    )
  },
  staffDetail: {
    path: '/staff-detail/:id',
    name: 'STAFF_MANAGEMENT_DETAIL',
    permission: appPermissions.staff.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/staff/StaffDetail')
    )
  },
  // Resident
  residentManagement: {
    path: '/residents',
    name: 'RESIDENT_MANAGEMENT',
    permission: appPermissions.resident.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/residents')
    )
  },
  residentCreate: {
    path: '/resident-create',
    name: 'RESIDENT_MANAGEMENT_CREATE',
    permission: appPermissions.resident.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/residents/ResidentDetail')
    )
  },
  residentDetail: {
    path: '/resident-detail/:id',
    name: 'RESIDENT_MANAGEMENT_DETAIL',
    permission: appPermissions.resident.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/residents/ResidentDetail')
    )
  },
  // Shop Owner
  shopOwnerManagement: {
    path: '/shop-owners',
    name: 'SHOP_OWNER_MANAGEMENT',
    permission: appPermissions.shopOwner.page,
    layout: layouts.portalLayout,
    icon: UserOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/member/shopOwner')
    )
  },
  shopOwnerCreate: {
    path: '/shop-owner-create',
    name: 'SHOP_OWNER_MANAGEMENT_CREATE',
    permission: appPermissions.shopOwner.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/shopOwner/ShopOwnerDetail')
    )
  },
  shopOwnerDetail: {
    path: '/shop-owner-detail/:id',
    name: 'SHOP_OWNER_MANAGEMENT_DETAIL',
    permission: appPermissions.shopOwner.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/shopOwner/ShopOwnerDetail')
    )
  },
  shopManagement: {
    path: '/shop-management',
    name: 'SHOP_MANAGEMENT',
    permission: appPermissions.shopOwner.updateShopOwner,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/shopManagement')
    )
  },
  shopProductList: {
    path: '/shop-product-list',
    name: 'SHOP_PRODUCT_LIST',
    permission: appPermissions.product.page,
    layout: layouts.portalLayout,
    icon: CodepenOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/member/shopProduct/productList/productList')
    )
  },
  shopProductCreate: {
    path: '/shop-product-create',
    name: 'SHOP_PRODUCT_CREATE',
    permission: appPermissions.product.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/member/shopProduct/productDetail/productDetail')
    )
  },
  shopProductDetail: {
    path: '/shop-product-detail/:id',
    name: 'SHOP_PRODUCT_DETAIL',
    permission: appPermissions.product.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/member/shopProduct/productDetail/productDetail')
    )
  },
  shopOrderList: {
    path: '/shop-order-list',
    name: 'SHOP_ORDER_LIST',
    permission: appPermissions.eOrders.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/shopOrder/shopOrderList')
    )
  },
  shopOrderDetail: {
    path: '/shop-order-detail/:id',
    name: 'SHOP_ORDER_DETAIL',
    permission: appPermissions.eOrders.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/member/shopOrder/shopOrderDetail')
    )
  },
  companies: {
    path: '/companies',
    name: 'COMPANY',
    permission: appPermissions.company.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/Company')
    )
  },
  companyCreate: {
    path: '/company-create',
    permission: appPermissions.company.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/Company/components/CompanyDetail')
    )
  },
  companyDetail: {
    path: '/company-detail/:id',
    permission: appPermissions.company.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/Company/components/CompanyDetail')
    )
  },
  companyContracts: {
    path: '/contracts',
    name: 'COMPANY_CONTRACT',
    permission: appPermissions.companyContract.page,
    layout: layouts.portalLayout,
    // icon: FileProtectOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/projects/Contract')
    )
  },
  companyContractCreate: {
    path: '/contract-create',
    permission: appPermissions.companyContract.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/projects/Contract/components/ContractDetail')
    )
  },
  companyContractDetail: {
    path: '/contract-detail/:id',
    permission: appPermissions.companyContract.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/projects/Contract/components/ContractDetail')
    )
  },
  contractCategories: {
    path: '/contract-categories',
    name: 'CONTRACT_CATEGORY',
    permission: appPermissions.companyContract.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/ContractCategory')
    )
  },
  contractCategoryCreate: {
    path: '/contract-category-create',
    permission: appPermissions.companyContract.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/projects/ContractCategory/components/ContractCategoryDetail'
        )
    )
  },
  contractCategoryDetail: {
    path: '/contract-category-detail/:id',
    permission: appPermissions.companyContract.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/projects/ContractCategory/components/ContractCategoryDetail'
        )
    )
  },
  // Project
  projectManagement: {
    path: '/projects',
    name: 'PROJECT_MANAGEMENT',
    permission: appPermissions.project.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/project')
    )
  },
  projectCreate: {
    path: '/project-create',
    name: 'PROJECT_MANAGEMENT_CREATE',
    permission: appPermissions.project.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/project/ProjectDetail')
    )
  },
  projectDetail: {
    path: '/project-detail/:id',
    name: 'PROJECT_MANAGEMENT_DETAIL',
    permission: appPermissions.project.read,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/project/ProjectDetail')
    )
  },
  projectBuilding: {
    path: '/buildings',
    name: 'PROJECT_BUILDING',
    permission: appPermissions.building.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/Building')
    )
  },
  projectFloor: {
    path: '/floors',
    name: 'PROJECT_FLOOR',
    permission: appPermissions.floor.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/projects/Floor'))
  },
  projectUnit: {
    path: '/units',
    name: 'PROJECT_UNIT',
    permission: appPermissions.unit.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/projects/unit'))
  },
  projectStackingPlan: {
    path: '/stacking-plan',
    name: 'PROJECT_STACKING_PLAN',
    permission: appPermissions.unit.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/stackingPlan')
    )
  },
  projectUnitCreate: {
    path: '/unit-create',
    permission: appPermissions.unit.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/unit/UnitDetail')
    )
  },
  projectUnitDetail: {
    path: '/unit-detail/:id',
    permission: appPermissions.unit.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/projects/unit/UnitDetail')
    )
  },
  // Library
  libraryManagement: {
    path: '/library',
    name: 'LIBRARY_MANAGEMENT',
    permission: appPermissions.library.page,
    layout: layouts.portalLayout,
    icon: FileTextOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/communication/library')
    )
  },
  // Communicate
  communicationWorkOrder: {
    path: '/work-order',
    name: 'WORKORDER',
    permission: appPermissions.workOrder.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/workorder')
    )
  },
  communicationMyWorkOrder: {
    path: '/my-work-order',
    name: 'MY_WORK_ORDER',
    permission: appPermissions.workOrder.myWorkOrder,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/workorder')
    )
  },
  communicationWorkOrderCreate: {
    path: '/work-order-create',
    name: 'WORK_ORDER_DETAIL',
    permission: appPermissions.workOrder.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/communication/workorder/components/WorkOrderDetail'
        )
    )
  },
  communicationWorkOrderDetail: {
    path: '/work-order-detail/:id',
    name: 'WORK_ORDER_DETAIL',
    permission: appPermissions.workOrder.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/communication/workorder/components/WorkOrderDetail'
        )
    )
  },
  communicationWorkOrderConfig: {
    path: '/work-order-config',
    name: 'WORK_ORDER_CONFIG',
    permission: appPermissions.workOrder.config,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/communication/workorder/components/WorkOrderConfig'
        )
    )
  },
  // Communication feedback
  communicationFeedback: {
    path: '/feedback',
    name: 'FEEDBACK',
    permission: appPermissions.feedback.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/feedback')
    )
  },
  communicationFeedbackCreate: {
    path: '/feedback-create',
    name: 'FEEDBACK_DETAIL',
    permission: appPermissions.feedback.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/communication/feedback/components/FeedbackDetail'
        )
    )
  },
  communicationFeedbackDetail: {
    path: '/feedback-detail/:id',
    name: 'FEEDBACK_DETAIL',
    permission: appPermissions.feedback.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/communication/feedback/components/FeedbackDetail'
        )
    )
  },
  communicationFeedbackConfig: {
    path: '/feedback-config',
    name: 'FEEDBACK_CONFIG',
    permission: appPermissions.feedback.config,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/communication/feedback/components/FeedbackConfig'
        )
    )
  },
  // Communication visitor
  communicationVisitor: {
    path: '/visitor',
    name: 'VISITOR',
    permission: appPermissions.visitor.page,
    layout: layouts.portalLayout,
    icon: SelectOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/communication/visitor')
    )
  },
  communicationVisitorCreate: {
    path: '/visitor-create',
    name: 'VISITOR_DETAIL',
    permission: appPermissions.visitor.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/communication/visitor/components/VisitorDetail')
    )
  },
  communicationVisitorDetail: {
    path: '/visitor-detail/:id',
    name: 'VISITOR_DETAIL',
    permission: appPermissions.visitor.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/communication/visitor/components/VisitorDetail')
    )
  },
  // Building Directory
  buildingDirectory: {
    path: '/building-directory',
    name: 'BUILDING_DIRECTORY',
    permission: appPermissions.buildingDirectory.page,
    layout: layouts.portalLayout,
    icon: ContactsOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/communication/buildingDirectory')
    )
  },
  buildingDirectoryCreate: {
    path: '/visitor-create',
    name: 'VISITOR_DETAIL',
    permission: appPermissions.visitor.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/communication/visitor/components/VisitorDetail')
    )
  },
  buildingDirectoryDetail: {
    path: '/visitor-detail/:id',
    name: 'VISITOR_DETAIL',
    permission: appPermissions.visitor.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/communication/visitor/components/VisitorDetail')
    )
  },
  // News
  newsManagement: {
    path: '/news',
    name: 'NEWS',
    permission: appPermissions.news.page,
    layout: layouts.portalLayout,
    icon: ReadOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/communication/news')
    )
  },
  newsDetail: {
    path: '/news-detail/:id',
    name: 'NEWS',
    permission: appPermissions.news.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/news/detail')
    )
  },
  newsEdit: {
    path: '/news-edit/:id',
    name: 'NEWS',
    permission: appPermissions.news.update,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/news/edit')
    )
  },
  newsCreate: {
    path: '/news-create',
    name: 'NEWS',
    permission: appPermissions.news.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/news/edit')
    )
  },
  // Events
  eventManagement: {
    path: '/events',
    name: 'EVENT',
    permission: appPermissions.event.page,
    layout: layouts.portalLayout,
    icon: ScheduleOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/communication/events')
    )
  },
  eventDetail: {
    path: '/event-detail/:id',
    name: 'EVENT',
    permission: appPermissions.event.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/events/detail')
    )
  },
  eventEdit: {
    path: '/event-edit/:id',
    name: 'EVENT',
    permission: appPermissions.event.update,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/events/edit')
    )
  },
  eventCreate: {
    path: '/event-create',
    name: 'EVENT',
    permission: appPermissions.event.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/communication/events/edit')
    )
  },
  // Amenity
  amenityManagement: {
    path: '/amenities',
    name: 'AMENITY',
    permission: appPermissions.amenity.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/booking/amenity')
    )
  },
  amenityDetail: {
    path: '/amenity-detail/:id',
    name: 'AMENITY_DETAIL',
    permission: appPermissions.amenity.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/booking/amenity/components/AmenityDetail')
    )
  },
  amenityCreate: {
    path: '/amenity-create',
    name: 'AMENITY_CREATE',
    permission: appPermissions.amenity.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/booking/amenity/components/AmenityDetail')
    )
  },
  // Amenity group
  amenityGroupManagement: {
    path: '/amenity-groups',
    name: 'AMENITY_GROUP',
    permission: appPermissions.amenityGroup.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/booking/amenityGroup')
    )
  },
  amenityGroupDetail: {
    path: '/amenity-group-detail/:id',
    name: 'AMENITY_GROUP_DETAIL',
    permission: appPermissions.amenityGroup.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/booking/amenityGroup/components/AmenityGroupDetail'
        )
    )
  },
  amenityGroupCreate: {
    path: '/amenity-group-create',
    name: 'AMENITY_GROUP_CREATE',
    permission: appPermissions.amenityGroup.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/booking/amenityGroup/components/AmenityGroupDetail'
        )
    )
  },
  // Reservation
  reservationManagement: {
    path: '/reservations',
    name: 'RESERVATION',
    permission: appPermissions.reservation.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/booking/reservation')
    )
  },
  reservationDetail: {
    path: '/reservation-detail/:id',
    name: 'RESERVATION_DETAIL',
    permission: appPermissions.reservation.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/booking/reservation/components/ReservationDetail'
        )
    )
  },
  reservationCreate: {
    path: '/reservation-create',
    name: 'RESERVATION_CREATE',
    permission: appPermissions.reservation.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/booking/reservation/components/ReservationCalendar'
        )
    )
  },
  // Amenity Blacklist
  amenityBlacklist: {
    path: '/amenity-black-list',
    name: 'BLACKLIST',
    permission: appPermissions.amenityBlacklist.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/booking/blackList')
    )
  },
  // Amenity booking overlap slot
  amenityMonthlyPackage: {
    path: '/amenity-monthly-package',
    name: 'MONTHLY_PACKAGE',
    permission: appPermissions.amenityMonthlyPackage.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/booking/monthlyPackage')
    )
  },
  // Fee Statement
  feePackage: {
    path: '/package-fee',
    name: 'FEE_PACKAGE',
    permission: appPermissions.feePackage.page,
    layout: layouts.portalLayout,
    icon: SplitCellsOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/fee-package')
    )
  },
  feeStatement: {
    path: '/fee-statement/:feeGroup',
    name: 'FEE_STATEMENT',
    permission: appPermissions.feeStatement.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/fee')
    )
  },
  feeStatementManagement: {
    path: '/fee-statement/feeManagement',
    name: 'FEE_STATEMENT',
    permission: appPermissions.feeStatement.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/fee')
    )
  },
  feeStatementBooking: {
    path: '/fee-statement/feeReservation',
    name: 'FEE_STATEMENT_BOOKING',
    permission: appPermissions.feeStatement.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/fee')
    )
  },
  feeCreateReceipt: {
    path: '/receipt-create',
    name: 'FEE_RECEIPT',
    permission: appPermissions.feeReceipt.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/feeStatement/receipt/components/CreatingReceipt'
        )
    )
  },
  feeCreateReceiptV1: {
    path: '/receipt-create-v1',
    name: 'FEE_RECEIPT',
    permission: appPermissions.feeReceipt.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/feeStatement/receipt/components/CreatingReceiptV1'
        )
    )
  },
  feeReceipt: {
    path: '/receipt',
    name: 'FEE_RECEIPT',
    permission: appPermissions.feeReceipt.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/receipt')
    )
  },
  feeReceiptDetail: {
    path: '/receipt-details/:id',
    name: 'FEE_RECEIPT_DETAIL',
    permission: appPermissions.feeReceipt.read,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/feeStatement/receipt/components/ReceiptDetails')
    )
  },
  feeVoucher: {
    path: '/voucher',
    name: 'FEE_VOUCHER',
    permission: appPermissions.feeVoucher.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/voucher')
    )
  },
  feeVoucherDetail: {
    path: '/voucher-detail',
    name: 'FEE_VOUCHER_DETAIL',
    permission: appPermissions.feeVoucher.read,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/voucher/PaymentPrinting')
    )
  },
  feeAuditLog: {
    path: '/fee/audit-log/:id',
    name: 'FEE_AUDIT_LOG',
    permission: '',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/fee-log')
    )
  },
  feeTypes: {
    path: '/fee-types',
    name: 'FEE_TYPE',
    permission: appPermissions.feeType.page,
    layout: layouts.portalLayout,
    icon: TagOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/fee-type')
    )
  },
  paymentSetting: {
    path: '/payment',
    name: 'FEE_PAYMENT_SETTING',
    permission: appPermissions.system.paymentSetting,
    layout: layouts.portalLayout,
    icon: DollarOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/feeStatement/payment-setting')
    )
  },
  // Announcement
  announcement: {
    path: '/announcement',
    name: 'ANNOUNCEMENT',
    permission: appPermissions.announcement.page,
    layout: layouts.portalLayout,
    icon: SoundOutlined,
    component: LoadableComponent(() => import('../../../scenes/announcement'))
  },
  announcementCreate: {
    path: '/announcement-create',
    name: 'ANNOUNCEMENT_CREATE',
    permission: appPermissions.announcement.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/announcement/components/AnnouncementDetail')
    )
  },
  announcementDetail: {
    path: '/announcement-detail/:id',
    name: 'ANNOUNCEMENT_DETAIL',
    permission: appPermissions.announcement.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/announcement/components/AnnouncementDetail')
    )
  },
  // Inventory
  inventoryBrand: {
    path: '/inventory-brand',
    name: 'INVENTORY_BRAND',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/brand')
    )
  },
  inventoryLocation: {
    path: '/inventory-location',
    name: 'INVENTORY_LOCATION',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/location')
    )
  },
  inventoryCategory: {
    path: '/inventory-category',
    name: 'INVENTORY_CATEGORY',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/category')
    )
  },
  inventoryCategoryDetail: {
    path: '/inventory-category-detail/:id',
    name: 'INVENTORY_CATEGORY_DETAIL',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/category/CategoryDetail')
    )
  },
  inventoryItems: {
    path: '/inventory-items',
    name: 'INVENTORY_ITEMS',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/inventoryItems')
    )
  },
  inventoryItemsCreate: {
    path: '/inventory-items-create',
    name: 'INVENTORY_ITEMS',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/inventoryItems/detail')
    )
  },
  inventoryItemsDetail: {
    path: '/inventory-items-detail/:id',
    name: 'INVENTORY_ITEMS',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/inventoryItems/detail')
    )
  },
  inventoryStockInOut: {
    path: '/inventory-stock-in-out',
    name: 'INVENTORY_STOCK_IN_OUT',
    permission: appPermissions.inventory.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/inventory/stockInOut')
    )
  },
  // inventoryWarehouse: {
  //   path: '/inventory-warehouse',
  //   name: 'INVENTORY_WAREHOUSE',
  //   permission: appPermissions.inventory.page,
  //   layout: layouts.portalLayout,
  //   component: LoadableComponent(() => import('../../../scenes/inventory/inventoryItems'))
  // },
  // Notification Template
  notificationTemplate: {
    path: '/notification-template',
    name: 'TEMPLATE_NOTIFICATION',
    permission: appPermissions.notificationTemplate.page,
    layout: layouts.portalLayout,
    icon: LayoutOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/notificationTemplate')
    )
  },
  notificationTemplateCreate: {
    path: '/notification-template-create',
    name: 'TEMPLATE_NOTIFICATION_CREATE',
    permission: appPermissions.notificationTemplate.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/notificationTemplate/components/TemplateDetail')
    )
  },
  notificationTemplateDetail: {
    path: '/notification-template-detail/:id',
    name: 'TEMPLATE_NOTIFICATION_DETAIL',
    permission: appPermissions.notificationTemplate.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import('../../../scenes/notificationTemplate/components/TemplateDetail')
    )
  },
  // Workflow
  settingWorkflowConfiguration: {
    path: '/settings/workflow/configuration',
    permission: 'PagesAdministration.Workflow',
    name: 'SETTING_WORKFLOW_CONFIGURATION',
    layout: layouts.portalLayout,
    icon: MoreOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/workflow/configuration')
    )
  },
  settingWorkflowStatus: {
    path: '/settings/workflow/status',
    permission: 'PagesAdministration.Workflow',
    name: 'SETTING_WORKFLOW_STATUS',
    layout: layouts.portalLayout,
    icon: FormatPainterOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/workflow/status')
    )
  },
  settingWorkflowPriority: {
    path: '/settings/workflow/priority',
    permission: 'PagesAdministration.Workflow',
    name: 'SETTING_WORKFLOW_PRIORITY',
    layout: layouts.portalLayout,
    icon: FieldNumberOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/workflow/priority')
    )
  },
  settingWorkflowRole: {
    path: '/settings/workflow/role',
    permission: 'PagesAdministration.Workflow',
    name: 'SETTING_WORKFLOW_ROLE',
    layout: layouts.portalLayout,
    icon: TagOutlined,
    component: LoadableComponent(() => import('../../../scenes/workflow/role'))
  },
  settingWorkflowTracker: {
    path: '/settings/workflow/tracker',
    permission: 'PagesAdministration.Workflow',
    name: 'SETTING_WORKFLOW_TRACKER',
    layout: layouts.portalLayout,
    icon: ProfileOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/workflow/tracker')
    )
  },
  settingWorkflowCustomField: {
    path: '/settings/workflow/custom-field',
    permission: 'PagesAdministration.Workflow',
    name: 'SETTING_WORKFLOW_CUSTOM_FIELD',
    layout: layouts.portalLayout,
    icon: FieldStringOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/workflow/customField')
    )
  },
  settingWorkflowSLA: {
    path: '/settings/workflow/sla',
    permission: 'PagesAdministration.Workflow',
    name: 'SETTING_WORKFLOW_SLA',
    layout: layouts.portalLayout,
    icon: FieldStringOutlined,
    component: LoadableComponent(() => import('../../../scenes/workflow/sla'))
  },
  // PlanMaintenance
  planMaintenanceStatistic: {
    path: '/plan-maintenance-statistic',
    name: 'PLAN_MAINTENANCE_STATISTIC',
    permission: appPermissions.planMaintenance.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/planMaintenance/calendar')
    )
  },
  planMaintenance: {
    path: '/plan-maintenance',
    name: 'PLAN_MAINTENANCE',
    permission: appPermissions.planMaintenance.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/planMaintenance/plan-maintenance')
    )
  },
  planMaintenanceEdit: {
    path: '/plan-maintenance-detail/:id',
    name: 'PLAN_MAINTENANCE_DETAIL',
    permission: appPermissions.planMaintenance.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/planMaintenance/plan-maintenance/components/PlanMaintenanceDetail'
        )
    )
  },
  planMaintenanceCreate: {
    path: '/plan-maintenance-create',
    name: 'PLAN_MAINTENANCE_CREATE',
    permission: appPermissions.planMaintenance.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/planMaintenance/plan-maintenance/components/PlanMaintenanceDetail'
        )
    )
  },
  // myPlanMaintenance
  myPlanMaintenance: {
    path: '/my-plan-maintenance',
    name: 'MY_PLAN_MAINTENANCE',
    permission: appPermissions.planMaintenance.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/planMaintenance/my-plan-maintenance')
    )
  },
  planMaintenancePipelineView: {
    path: '/plan-maintenance-pipeline',
    name: 'PLAN_MAINTENANCE_PIPELINE_VIEW',
    permission: appPermissions.planMaintenance.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/planMaintenance/pipeline-view')
    )
  },
  // Asset
  assets: {
    path: '/asset-management',
    name: 'ASSET',
    permission: appPermissions.asset.page,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/facility/assets')
    )
  },
  assetCreate: {
    path: '/asset-create',
    name: 'ASSET_CREATE',
    permission: appPermissions.asset.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/facility/assetDetail')
    )
  },
  assetDetail: {
    path: '/asset-detail/:code',
    name: 'ASSET_DETAIL',
    permission: appPermissions.asset.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/facility/assetDetail')
    )
  },
  // AssetType
  assetTypes: {
    path: '/asset-types',
    name: 'ASSET_TYPES',
    permission: 'PagesAdministration.Workflow',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/facility/assetType')
    )
  },
  // Admin
  adminUser: {
    path: '/users',
    permission: appPermissions.adminUser.page,
    title: 'Users',
    name: 'ADMINISTRATION_USER',
    layout: layouts.portalLayout,
    icon: UserOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/Users')
    )
  },
  adminRole: {
    path: '/roles',
    permission: appPermissions.adminRole.page,
    title: 'Roles',
    name: 'ADMINISTRATION_ROLE',
    layout: layouts.portalLayout,
    icon: SolutionOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/Roles')
    )
  },
  adminTenants: {
    path: '/tenants',
    permission: appPermissions.adminTenant.page,
    title: 'Tenants',
    name: 'ADMINISTRATION_TENANT',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/Tenants')
    )
  },
  adminLanguages: {
    path: '/language',
    permission: appPermissions.adminLanguage.page,
    title: 'Languages',
    name: 'ADMINISTRATION_LANGUAGE',
    layout: layouts.portalLayout,
    icon: TranslationOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/Languages')
    )
  },
  adminLanguageTexts: {
    path: '/language-text/:id',
    permission: appPermissions.adminLanguage.changeText,
    title: 'ADMINISTRATION_LANGUAGE_TEXT',
    name: 'ADMINISTRATION_LANGUAGE_TEXT',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () =>
        import(
          '../../../scenes/administrator/Languages/components/languageTexts'
        )
    )
  },
  adminBanner: {
    path: '/banner',
    permission: appPermissions.adminRole.page,
    title: 'Banners',
    name: 'ADMINISTRATION_BANNER',
    layout: layouts.portalLayout,
    icon: FileImageOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/Banners')
    )
  },

  // Sale and Lease
  saleManagement: {
    path: '/sales',
    permission: appPermissions.enquiry.page,
    title: 'Sale',
    name: 'SALE',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/saleAndLease/sale')
    )
  },
  saleCreate: {
    path: '/create-sales',
    permission: appPermissions.enquiry.page,
    title: 'Create Sale',
    name: 'CREATE_SALE',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/saleAndLease/sale/SaleDetail')
    )
  },
  saleDetail: {
    path: '/sales-detail/:id',
    permission: appPermissions.enquiry.page,
    title: 'Sale Detail',
    name: 'SALE_DETAIL',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/saleAndLease/sale/SaleDetail')
    )
  },
  leaseManagement: {
    path: '/lease',
    permission: appPermissions.enquiry.page,
    title: 'Lease',
    name: 'LEASE',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/saleAndLease/lease')
    )
  },
  leaseCreate: {
    path: '/create-lease',
    permission: appPermissions.enquiry.page,
    title: 'Create Lease',
    name: 'CREATE_LEASE',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/saleAndLease/lease/LeaseDetail')
    )
  },
  leaseDetail: {
    path: '/lease-detail/:id',
    permission: appPermissions.enquiry.page,
    title: 'Lease Detail',
    name: 'LEASE_DETAIL',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/saleAndLease/lease/LeaseDetail')
    )
  },
  // Handover
  handoverPlan: {
    path: '/handover-plan',
    permission: appPermissions.handoverPlan.page,
    title: 'Handover Plan',
    name: 'HANDOVER_PLAN',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/handover/planHandover')
    )
  },
  handoverPlanCreate: {
    path: '/handover-plan-create',
    permission: appPermissions.handoverPlan.create,
    title: 'Handover Plan Create',
    name: 'HANDOVER_PLAN_CREATE',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/handover/planHandover/Details')
    )
  },
  handoverPlanDetail: {
    path: '/handover-plan-detail/:id',
    permission: appPermissions.handoverPlan.detail,
    title: 'Handover Plan Detail',
    name: 'HANDOVER_PLAN_DETAIL',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/handover/planHandover/Details')
    )
  },
  handoverReservation: {
    path: '/handover-reservation',
    permission: appPermissions.handoverReservation.page,
    title: 'Handover Reservation',
    name: 'HANDOVER_RESERVATION',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/handover/handoverProcess')
    )
  },
  handoverReservationCreate: {
    path: '/handover-reservation-create',
    permission: appPermissions.handoverReservation.create,
    title: 'Handover Reservation Create',
    name: 'HANDOVER_RESERVATION_CREATE',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/handover/handoverProcess/Details')
    )
  },
  handoverReservationDetail: {
    path: '/handover-reservation-detail/:id',
    permission: appPermissions.handoverReservation.detail,
    title: 'Handover Reservation Detail',
    name: 'HANDOVER_RESERVATION_DETAIL',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/handover/handoverProcess/Details')
    )
  },
  // E-form
  eForms: {
    path: '/e-form',
    permission: appPermissions.eForm.page,
    title: 'EFORM_LIST',
    name: 'EFORM_LIST',
    layout: layouts.portalLayout,
    component: LoadableComponent(() => import('../../../scenes/eform'))
  },
  eFormCreate: {
    path: '/e-form-create',
    name: 'EFORM_CREATE',
    permission: appPermissions.eForm.create,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/eform/components/EFormCreateOrEdit')
    )
  },
  eFormEdit: {
    path: '/e-form/:id',
    name: 'EFORM_DETAIL',
    permission: appPermissions.eForm.detail,
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes//eform/components/EFormDetail')
    )
  },
  eFormResponses: {
    path: '/e-form-response',
    permission: appPermissions.eFormAnswer.page,
    title: 'EFORM_RESPONSE_LIST',
    name: 'EFORM_RESPONSE_LIST',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/eform/eFormResponse')
    )
  },
  eFormResponseDetail: {
    path: '/e-form-response/:id',
    permission: appPermissions.eFormAnswer.page,
    title: 'EFORM_RESPONSE_LIST',
    name: 'EFORM_RESPONSE_LIST',
    layout: layouts.portalLayout,
    component: LoadableComponent(
      () => import('../../../scenes/eform/eFormResponse/EFormResponseDetail')
    )
  },

  // Config Term
  termCondition: {
    path: '/term-condition-form',
    name: 'TERM_CONDITION_SETTING',
    permission: appPermissions.adminMasterData.page,
    title: 'TEM_CONDITION_SETTING',
    layout: layouts.portalLayout,
    icon: RadarChartOutlined,
    component: LoadableComponent(
      () => import('../../../scenes/administrator/TermConditionForm')
    )
  }
}

export const routers: any = {
  ...userLayout,
  ...portalLayouts
}

export const appMenuGroups: any = [
  {
    name: 'DASHBOARD_GROUP',
    isGroup: true,
    children: [routers.dashboard, routers.report, routers.dashboardBI]
  },
  {
    name: 'PMS_PRODUCT_GROUP',
    isGroup: true,
    children: [
      {
        name: 'USER_MANAGEMENT_GROUP',
        icon: UserOutlined,
        children: [routers.staffManagement, routers.residentManagement]
      },
      {
        name: 'COMPANY_MANAGEMENT',
        icon: FileProtectOutlined,
        children: [
          routers.companies,
          routers.companyContracts,
          routers.contractCategories
        ]
      },
      {
        name: 'SALE_AND_LEASE',
        icon: CustomerServiceOutlined,
        children: [routers.saleManagement, routers.leaseManagement]
      },
      {
        name: 'HANDOVER',
        icon: InteractionOutlined,
        children: [routers.handoverPlan, routers.handoverReservation]
      },

      {
        name: 'EZ_SHOPPING',
        icon: ShopOutlined,
        children: [
          routers.shopOwnerManagement,
          routers.shopProductList,
          routers.shopOrderList,
          routers.shopManagement
        ]
      },
      {
        name: 'PROJECT_MANAGEMENT_GROUP',
        icon: ClusterOutlined,
        children: [
          routers.projectManagement,
          routers.projectBuilding,
          routers.projectFloor,
          routers.projectUnit,
          routers.projectStackingPlan
        ]
      },
      routers.libraryManagement,
      routers.newsManagement,
      routers.eventManagement,
      routers.announcement,
      {
        name: 'FEEDBACK_GROUP',
        permission: appPermissions.feedback.page,
        icon: CommentOutlined,
        children: [
          routers.communicationFeedback,
          routers.communicationFeedbackConfig
        ]
      },
      routers.communicationVisitor,
      routers.buildingDirectory,
      {
        name: 'WORKORDER_GROUP',
        permission: appPermissions.workOrder.page,
        icon: AuditOutlined,
        children: [
          routers.communicationWorkOrder,
          routers.communicationMyWorkOrder,
          routers.communicationWorkOrderConfig
        ]
      },
      {
        name: 'RESERVATION_GROUP',
        permission: appPermissions.amenity.page,
        icon: CarryOutOutlined,
        children: [
          routers.amenityGroupManagement,
          routers.amenityManagement,
          routers.reservationManagement,
          routers.reservationCreate,
          routers.amenityBlacklist,
          routers.amenityMonthlyPackage
        ]
      },
      {
        name: 'FEE_STATEMENT_GROUP',
        icon: DollarOutlined,
        children: [
          routers.feeStatementManagement,
          routers.feeStatementBooking,
          routers.feeReceipt,
          routers.feeVoucher
        ]
      },
      {
        name: 'INVENTORY_GROUP',
        icon: ControlOutlined,
        children: [
          routers.inventoryItems,
          routers.inventoryStockInOut,
          routers.inventoryCategory,
          routers.inventoryBrand,
          routers.inventoryLocation
        ]
      },
      {
        name: 'PLAN_MAINTENANCE_GROUP',
        icon: ScheduleOutlined,
        children: [
          routers.planMaintenanceStatistic,
          routers.planMaintenance,
          routers.myPlanMaintenance,
          routers.planMaintenancePipelineView
        ]
      },
      {
        name: 'ASSET_MANAGEMENT_GROUP',
        icon: BarcodeOutlined,
        children: [routers.assets, routers.assetTypes]
      },
      {
        name: 'E_FORM_GROUP',
        icon: FileTextOutlined,
        children: [routers.eForms, routers.eFormResponses]
      }
    ]
  },
  {
    name: 'PMS_SETTING_GROUP',
    isGroup: true,
    children: [
      routers.paymentSetting,
      routers.feePackage,
      routers.feeTypes,
      routers.notificationTemplate,
      routers.adminBanner,
      routers.adminUser,
      routers.adminRole,
      routers.adminLanguages,
      routers.adminMasterData,
      routers.appSetting,
      routers.termCondition
    ]
  }
]
