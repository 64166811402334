import Filter from '@components/Filter'
import { L } from '@lib/abpUtility'
import { dateFormat } from '@lib/appconst'
import SaleAndLeaseStore from '@stores/saleAndLease/saleAndLeaseStore'
import { Col, DatePicker, Row, Select } from 'antd'
import Search from 'antd/lib/input/Search'
import React from 'react'

type Props = {
  handleSearch: any
  saleAndLeaseStore: SaleAndLeaseStore
}
export const bathroomStatus = [
  {
    value: 1,
    get label() {
      return L('1_BATHROOM')
    }
  },
  {
    value: 2,
    get label() {
      return L('2_BATHROOM')
    }
  },
  {
    value: 3,
    get label() {
      return L('3_BATHROOM')
    }
  },
  {
    value: 4,
    get label() {
      return L('4_BATHROOM')
    }
  }
]
export const bedroomStatus = [
  {
    value: 1,
    get label() {
      return L('1_BEDROOM')
    }
  },
  {
    value: 2,
    get label() {
      return L('2_BEDROOM')
    }
  },
  {
    value: 3,
    get label() {
      return L('3_BEDROOM')
    }
  },
  {
    value: 4,
    get label() {
      return L('4_BEDROOM')
    }
  }
]
const FilterSale = (props: Props) => {
  React.useEffect(() => {
    props.saleAndLeaseStore.getListStatus()
  }, [])
  const keywordPlaceholder = ``
  return (
    <Filter title={L('FILTER')} handleRefresh={() => props.handleSearch()}>
      <Row gutter={[16, 8]}>
        <Col sm={{ span: 8, offset: 0 }}>
          <label>{L('FILTER_KEYWORD')}</label>
          <Search
            placeholder={keywordPlaceholder}
            onSearch={(value) => props.handleSearch('keyword', value)}
          />
        </Col>
        <Col sm={{ span: 8, offset: 0 }}>
          <label>{L('FILTER_BEDROOM')}</label>
          <Select
            mode="multiple"
            className="full-width"
            filterOption={false}
            allowClear
            onChange={(value) => props.handleSearch('numOfBedroom', value)}>
            {bedroomStatus.map((item: any, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col sm={{ span: 8, offset: 0 }}>
          <label>{L('FILTER_BATHROOM')}</label>
          <Select
            mode="multiple"
            className="full-width"
            filterOption={false}
            allowClear
            onChange={(value) => props.handleSearch('numOfBathroom', value)}>
            {bathroomStatus.map((item: any, index) => (
              <Select.Option key={index} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col sm={{ span: 8, offset: 0 }}>
          <label>{L('STATUS')}</label>
          <Select
            allowClear
            className="w-100"
            showSearch
            placeholder={L('SELECT_STATUS')}
            optionFilterProp="children"
            onChange={(value) => props.handleSearch('StatusIds', value)}
            mode="multiple">
            {props.saleAndLeaseStore.statusList.map((status) => {
              return (
                <Select.Option value={status.id} key={status.id}>
                  {L(status.code)}
                </Select.Option>
              )
            })}
          </Select>
        </Col>
        <Col sm={{ span: 16, offset: 0 }}>
          <label>{L('FILTER_CREATE_TIME')}</label>
          <DatePicker.RangePicker
            className="w-100"
            format={dateFormat}
            onChange={(dates) => props.handleSearch('date', dates)}
          />
        </Col>
      </Row>
    </Filter>
  )
}

export default FilterSale
