import DataTable from '@components/DataTable'
import { isGranted, L } from '@lib/abpUtility'
import SaleAndLeaseStore from '@stores/saleAndLease/saleAndLeaseStore'
import Stores from '@stores/storeIdentifier'
import { Button, Table } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import FilterSale from './FilterSale'
import getColumns from './column'
import AppConsts, { appPermissions } from '@lib/appconst'
import { useNavigate } from 'react-router-dom'
import { portalLayouts } from '@components/Layout/Router/router.config'
import { CheckOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons'
import saleAndLeaseService from '@services/saleAndLease/saleAndLeaseService'
import { convertFilterDate, notifySuccess } from '@lib/helper'
import SaleAndLeaseConfig from '../SaleAndLeaseConfig'
const { align } = AppConsts

type Props = {
  saleAndLeaseStore: SaleAndLeaseStore
}

export const enquiryType = {
  sale: 'Sales',
  lease: 'Leases'
}
const SaleManagement = inject(Stores.SaleAndLeaseStore)(
  observer((props: Props) => {
    const [filter, setFilter] = React.useState<any>({
      maxResultCount: 10,
      skipCount: 0,
      keyword: ''
    })
    const currentPage = () =>
      Math.floor(filter.skipCount / filter.maxResultCount) + 1
    const handleSearch = async (name, value) => {
      if (name === 'date') {
        setFilter(convertFilterDate(filter, value))
      } else {
        const newFilter = { ...filter, [name]: value }
        setFilter(newFilter)
      }
    }
    React.useEffect(() => {
      props.saleAndLeaseStore.getAllSale(filter)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])
    const navigate = useNavigate()
    const gotoDetail = async (id?) => {
      id
        ? navigate(portalLayouts.saleDetail.path.replace(':id', id))
        : navigate(portalLayouts.saleCreate.path)
    }
    const handleTableChange = (pagination: any) => {
      const newFilter = {
        ...filter,
        skipCount: (pagination.current - 1) * filter.maxResultCount
      }
      setFilter(newFilter)
    }
    const handleComplete = async (id) => {
      await saleAndLeaseService.completeEnquiry(id)
      notifySuccess(L('SUCCESSFULLY'), L('CHANGE_SUCCESSFULLY'))
      setFilter({ ...filter })
    }
    const column = getColumns({
      title: L('ACTIONS'),
      dataIndex: 'operation',
      key: 'operation',
      fixed: align.right,
      align: align.right,
      width: 90,
      render: (text: string, item: any) => {
        const statusId = item.status?.id || 0
        return (
          <div>
            {isGranted(appPermissions.enquiry.update) && (
              <>
                <Button
                  size="small"
                  className="ml-1"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => gotoDetail(item.id)}
                />
                {(statusId !== 4 || statusId !== 5) && (
                  <Button
                    size="small"
                    className="ml-1"
                    shape="circle"
                    disabled={item.enquiryStatusId === 5}
                    icon={<CheckOutlined />}
                    onClick={() => handleComplete(item.id)}
                  />
                )}
              </>
            )}
          </div>
        )
      }
    })
    const [settingVisible, setSettingVisible] = React.useState(false)
    const openSettingModal = () => {
      setSettingVisible(!settingVisible)
    }
    return (
      <>
        <FilterSale
          handleSearch={handleSearch}
          saleAndLeaseStore={props.saleAndLeaseStore}
        />
        <DataTable
          title={L('SALE_LIST')}
          onCreate={() => gotoDetail(null)}
          pagination={{
            pageSize: filter.maxResultCount,
            current: currentPage(),
            total: props.saleAndLeaseStore.saleList.totalCount ?? 0,
            onChange: handleTableChange
          }}
          createPermission={appPermissions.enquiry.create}
          actionGroups={() =>
            isGranted(appPermissions.enquiry.update) && (
              <Button
                onClick={openSettingModal}
                icon={<SettingOutlined />}
                type="primary"
                size={'large'}
                shape="circle"
                className="mx-3"
              />
            )
          }>
          <Table
            size="middle"
            scroll={{ x: 1000, y: 500 }}
            className="custom-ant-table"
            rowKey={(record) => record.id}
            columns={column}
            pagination={false}
            loading={props.saleAndLeaseStore.isLoading}
            dataSource={props.saleAndLeaseStore.saleList.items ?? []}
          />
        </DataTable>
        <SaleAndLeaseConfig
          enquiryType={1}
          visible={settingVisible}
          saleAndLeaseStore={props.saleAndLeaseStore}
          closeModal={openSettingModal}
        />
      </>
    )
  })
)

export default SaleManagement
