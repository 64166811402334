export default class Stores {
  static AuthenticationStore = 'authenticationStore'
  static RoleStore = 'roleStore'
  static TenantStore = 'tenantStore'
  static UserStore = 'userStore'
  static SessionStore = 'sessionStore'
  static AccountStore = 'accountStore'
  static LanguageStore = 'languageStore'
  static MasterDataStore = 'masterDataStore'
  // Project
  static ProjectStore = 'projectStore'
  static BuildingStore = 'buildingStore'
  static FloorStore = 'floorStore'
  static UnitStore = 'unitStore'
  static CompanyStore = 'companyStore'
  static ContractStore = 'contractStore'
  static ContractCategoryStore = 'contractCategoryStore'
  // Resident & Staff & Shop owner
  static ResidentStore = 'residentStore'
  static StaffStore = 'staffStore'
  static ShopOwnerStore = 'shopOwnerStore'
  static ShopProductStore = 'shopProductStore'
  static ShopOrderStore = 'shopOrderStore'
  // News & Event
  static NewsStore = 'newsStore'
  static NewsCategoryStore = 'newsCategoryStore'
  static EventStore = 'eventStore'
  static EventCategoryStore = 'eventCategoryStore'
  // Library
  static LibraryStore = 'libraryStore'
  // Feedback
  static FeedbackStore = 'feedbackStore'
  // Visitor
  static VisitorStore = 'visitorStore'
  // Inventory
  static InventoryBrandStore = 'inventoryBrandStore'
  static InventoryLocationStore = 'inventoryLocationStore'
  static InventoryCategoryStore = 'inventoryCategoryStore'
  static InventoryStockInOutStore = 'inventoryStockInOutStore'
  static InventoryItemsStore = 'inventoryItemsStore'
  static InventoryWarehouseStore = 'inventoryWarehouseStore'
  // Fee Statement
  static FeeStore = 'feeStore'
  static PackageFeeStore = 'packageFeeStore'
  static ReceiptStore = 'receiptStore'
  static VoucherStore = 'voucherStore'
  static FeeTypeStore = 'feeTypeStore'
  static FeeGroupStore = 'feeGroupStore'
  // Workflow
  static WfStatusStore = 'wfStatusStore'
  static WfPriorityStore = 'wfPriorityStore'
  static WfCustomFieldStore = 'wfCustomFieldStore'
  static WfRoleStore = 'wfRoleStore'
  static WfTrackerStore = 'wfTrackerStore'
  static WfConfigurationStore = 'wfConfigurationStore'
  static WorkflowStore = 'workflowStore'
  // Asset
  static AssetStore = 'assetStore'
  static AssetTypeStore = 'assetTypeStore'

  // File
  static FileStore = 'fileStore'

  // WorkOrder
  static WorkOrderStore = 'workOrderStore'

  // Common
  static AuditLogStore = 'auditLogStore'
  static CommentStore = 'commentStore'
  static NotificationTemplateStore = 'notificationTemplateStore'

  // Booking
  static AmenityStore = 'amenityStore'
  static AmenityGroupStore = 'amenityGroupStore'
  static ReservationStore = 'reservationStore'
  static AmenityLimitStore = 'amenityLimitStore'

  // Announcement
  static AnnouncementStore = 'announcementStore'
  static BuildingDirectoryStore = 'buildingDirectoryStore'

  // Team
  static TeamStore = 'teamStore'

  // Plan Maintenance
  static PlanMaintenanceStore = 'planMaintenanceStore'
  static PlanMaintenanceTaskStore = 'planMaintenanceTaskStore'
  static PlanMaintenanceCalendarStore = 'planMaintenanceCalendarStore'
  static PlanMaintenancePipelineStore = 'planMaintenancePipelineStore'

  // Reminder
  static ReminderStore = 'reminderStore'

  // Sale and Lease
  static SaleAndLeaseStore = 'saleAndLeaseStore'
  // Handover
  static HandoverStore = 'handoverStore'

  // EForm
  static EFormStore = 'eFormStore'
  static DashboardStore = 'dashboardStore'
  static TermConditionStore = 'termConditionStore'
}
