import * as React from 'react'
import { Button, Col, Pagination, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { isGranted, L } from '../../lib/abpUtility'
import './DataTable.less'

export interface IDataTableProps {
  title?: string
  textAddNew?: string
  onCreate?: () => void
  pagination?: any
  createPermission?: string
  actionGroups?: () => void
  showChangePageSize?: boolean
}

const DataTable: React.FunctionComponent<IDataTableProps> = ({
  title,
  textAddNew,
  onCreate,
  pagination,
  createPermission,
  actionGroups,
  showChangePageSize,
  ...props
}) => {
  const handleCreate = () => {
    onCreate && onCreate()
  }
  const handleOnChange = (page, pageSize) => {
    if (pagination.onChange) {
      pagination.onChange({ current: page, pageSize: pageSize })
    }
  }

  return (
    <>
      <Row className={'mt-3 mb-2 table-header'}>
        <Col
          xs={{ span: 10, offset: 0 }}
          sm={{ span: 10, offset: 0 }}
          md={{ span: 10, offset: 0 }}
          lg={{ span: 10, offset: 0 }}
          xl={{ span: 10, offset: 0 }}
          xxl={{ span: 10, offset: 0 }}
          className="title">
          <h3>{title}</h3>
        </Col>
        <Col
          className={'text-right'}
          xs={{ span: 14, offset: 0 }}
          sm={{ span: 14, offset: 0 }}
          md={{ span: 14, offset: 0 }}
          lg={{ span: 14, offset: 0 }}
          xl={{ span: 14, offset: 0 }}
          xxl={{ span: 14, offset: 0 }}>
          {actionGroups && actionGroups()}
          {onCreate && (!createPermission || isGranted(createPermission)) && (
            <Button
              type="primary"
              size={'large'}
              shape="circle"
              icon={<PlusOutlined />}
              onClick={handleCreate}
              style={{ boxShadow: '0px 4px 8px rgba(110, 186, 196, 0.2)' }}>
              {textAddNew}
            </Button>
          )}
        </Col>
      </Row>
      {props.children}
      {pagination && pagination.total > 0 && (
        <Row className="mt-3 pb-3">
          <Col sm={{ span: 24, offset: 0 }} style={{ textAlign: 'end' }}>
            <Pagination
              size="small"
              showTotal={(total) => L('TOTAL_{0}_ITEMS', total)}
              {...pagination}
              onChange={handleOnChange}
              showSizeChanger={!!showChangePageSize}
              pageSizeOptions={[10, 20, 50, 100, 500]}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default DataTable
