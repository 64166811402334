import Icon from '@ant-design/icons'

const FilterImage = () => <img src="assets/icons/filter.svg" />
const ExcelImage = () => <img src="/assets/icons/excel.svg" />
const PdfImage = () => <img src="assets/icons/pdf.svg" />
const WordImage = () => <img src="assets/icons/word.svg" />
const PowerPointImage = () => <img src="assets/icons/power-point.svg" />
const ImageFileImage = () => <img src="assets/icons/image-file.svg" />
const OtherFileImage = () => <img src="assets/icons/other-file.svg" />
const BuildingImage = () => <img src="assets/icons/.svg" />

export const FilterIcon = (props) => <Icon component={FilterImage} {...props} />
export const ExcelIcon = (props) => <Icon component={ExcelImage} {...props} />
export const PdfIcon = (props) => <Icon component={PdfImage} {...props} />
export const WordIcon = (props) => <Icon component={WordImage} {...props} />
export const PowerPointIcon = (props) => (
  <Icon component={PowerPointImage} {...props} />
)
export const ImageIcon = (props) => (
  <Icon component={ImageFileImage} {...props} />
)
export const OtherFileIcon = (props) => (
  <Icon component={OtherFileImage} {...props} />
)
export const BuildingIcon = (props) => (
  <Icon component={BuildingImage} {...props} />
)
