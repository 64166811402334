import * as React from 'react'

import Router from './components/Layout/Router'
import SessionStore from './stores/sessionStore'
import SignalRAspNetCoreHelper from './lib/signalRAspNetCoreHelper'
import Stores from './stores/storeIdentifier'
import { inject } from 'mobx-react'
import withSplashScreen from '@components/Layout/SplashScreen'
import ProjectStore from '@stores/project/projectStore'
import AppConsts from '@lib/appconst'
const { authorization } = AppConsts

export interface IAppProps {
  location: any
  sessionStore?: SessionStore
  projectStore?: ProjectStore
}

@inject(Stores.SessionStore, Stores.ProjectStore)
class App extends React.Component<IAppProps> {
  state = { isLoading: true, needLoginStep: undefined }

  async componentDidMount() {
    await this.props.sessionStore!.getCurrentLoginInformations()

    if (
      !!this.props.sessionStore!.currentLogin.user &&
      this.props.sessionStore!.currentLogin.application.features['SignalR']
    ) {
      SignalRAspNetCoreHelper.initSignalR()
      // if (
      //   this.props.sessionStore!.currentLogin.application.features[
      //     'SignalR.AspNetCore'
      //   ]
      // ) {
      //   SignalRAspNetCoreHelper.initSignalR()
      // }
    }

    if (
      !!this.props.sessionStore!.currentLogin.user &&
      localStorage.getItem(authorization.projectId)
    ) {
      await Promise.all([
        this.props.sessionStore!.getOwnProjects({}),
        this.props.sessionStore!.getWebConfiguration()
      ])
    }

    this.setState({ isLoading: false })
  }

  public render() {
    if (this.state.isLoading) return null

    return <Router />
  }
}

export default withSplashScreen(App)
