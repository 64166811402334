import type { PagedResultDto } from '@services/dto/pagedResultDto'
import http from '../httpService'
import { downloadFile } from '@lib/helperFile'
import { notifyError, notifySuccess } from '@lib/helper'
import { L, LNotification } from '@lib/abpUtility'

class VoucherService {
  public async create(body: any) {
    if (!body.feeDetails || !body.feeDetails.length) {
      notifyError(L('ERROR'), L('PLEASE_SELECT_FEE_ITEM_TO_MAKE_RECEIPT'))
      throw Error(L('PLEASE_SELECT_FEE_ITEM_TO_MAKE_RECEIPT'))
    }
    const result = await http.post(
      '/api/services/app/ExpenseMandate/Create',
      body
    )
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('ITEM_CREATE_SUCCEED')
    )
    return result.data.result
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const result = await http.get('/api/services/app/ExpenseMandate/GetAll', {
      params
    })
    return result.data.result
  }

  public async delete(params) {
    return http.delete('/api/services/app/ExpenseMandate/Remove', { params })
  }

  public async getOverview(params) {
    const res = await http.get(
      'api/services/app/ExpenseMandate/GetExpenseMandatesOverview',
      { params }
    )
    return res.data.result
  }

  public async downloadVouchers(params) {
    const response = await http.get('/api/FeeStatements/ExportExpenseMandate', {
      responseType: 'blob',
      params
    })
    downloadFile(response.data, 'fee-receipts.xlsx')
  }
}

export default new VoucherService()
