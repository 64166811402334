import http from '@services/httpService'
import { FeeTypeModel, IFeeType } from '@models/fee'
import { notifySuccess } from '@lib/helper'
import { LNotification } from '@lib/abpUtility'

class FeeTypeService {
  public async create(body) {
    const result = await http.post('api/services/app/FeeType/Create', body)
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('SAVING_SUCCESSFULLY')
    )
    return result.data.result
  }

  public async update(body) {
    const result = await http.put('api/services/app/FeeType/Update', body)
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('SAVING_SUCCESSFULLY')
    )
    return result.data.result
  }

  public async activateOrDeactivate(id, isActive) {
    const result = await http.post(
      'api/services/app/FeeType/Active',
      { id },
      { params: { isActive } }
    )
    notifySuccess(
      LNotification('SUCCESS'),
      LNotification('SAVING_SUCCESSFULLY')
    )
    return result.data.result
  }

  public async delete(id) {
    const result = await http.delete('api/services/app/FeeType/Delete', {
      params: { id }
    })
    return result.data
  }

  public async get(id): Promise<any> {
    const result = await http.get(
      'api/services/app/FeeType/GetFeeTypeForEdit',
      { params: { id } }
    )
    return FeeTypeModel.assign(result.data.result || {})
  }

  public async checkExist(code): Promise<any> {
    const result = await http.post(
      'api/services/app/FeeType/CheckExistCode',
      null,
      { params: { code } }
    )
    return result.data.result
  }

  public async getAll(params): Promise<any> {
    const result = await http.get('api/services/app/FeeType/GetAll', {
      params: params
    })
    return result.data.result
  }

  public async getList(params): Promise<IFeeType[]> {
    const res = await http.get('api/services/app/FeeType/GetLists', { params })
    return Promise.resolve(res.data.result)
  }
}

export default new FeeTypeService()
