import type { PagedResultDto } from '@services/dto/pagedResultDto'
import { IFeeType } from '@models/fee'
import { action, observable, makeObservable } from 'mobx'
import feeTypeService from '@services/fee/feeTypeService'
import { initMultiLanguageField } from '@lib/helper'

class FeeTypeStore {
  @observable pagedResult!: PagedResultDto<IFeeType>
  @observable isLoading!: boolean
  @observable feeTypes!: IFeeType[]
  @observable editFeeType!: IFeeType
  @observable filters!: any
  constructor() {
    makeObservable(this)
    this.pagedResult = {
      items: [],
      totalCount: 0
    }
    this.filters = {}
  }

  @action
  public setFilter(key, value) {
    this.filters = { ...this.filters, [key as any]: value }
  }

  @action
  async create(body) {
    await feeTypeService.create(body)
  }

  @action
  async update(body) {
    await feeTypeService.update(body)
  }

  @action
  async activateOrDeactivate(id, isActive) {
    await feeTypeService.activateOrDeactivate(id, isActive)
  }

  @action
  async delete(id) {
    await feeTypeService.delete(id)
    this.pagedResult.items = this.pagedResult.items.filter((x) => x.id !== id)
  }

  @action
  async get(id) {
    const result = await feeTypeService.get(id)
    this.editFeeType = result
  }

  @action
  async createFeeType() {
    this.editFeeType = {
      id: 0,
      names: initMultiLanguageField(),
      code: '',
      description: ''
    }
  }

  @action
  async getAll(params) {
    this.isLoading = true
    const result = await feeTypeService
      .getAll(params)
      .finally(() => (this.isLoading = false))
    this.pagedResult = result
  }

  @action
  async getLists(params) {
    this.pagedResult.items = await feeTypeService.getList({
      ...params
    })
  }
}

export default FeeTypeStore
